import React, { useEffect, useState } from 'react'
import { useFirebase } from 'react-redux-firebase'
import { Container, Row, Col, Spinner, Card, CardBody, CardTitle, Button } from 'reactstrap'
import { Divider, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { use } from 'i18next'


const buttonStyle = {
  backgroundColor: '#FFFFFF',
  border: 0,
  borderRadius: '0.5rem',
  boxSizing: 'border-box',
  color: '#111827',
  // fontFamily: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji",
  fontSize: '.875rem',
  fontWeight: 600,
  lineHeight: '1.25rem',
  padding: '0.75rem 1rem',
  textAlign: 'center',
  textDecoration: 'none #D1D5DB solid',
  textDecorationThickness: 'auto',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)',
  cursor: 'pointer',
  userSelect: 'none',
  touchAction: 'manipulation',
  width: 100,
  height: 50,
  margin: 5,
}


const isInIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

function PaymentOptions({ selectedGw, selectedGroup, provider, disabledmethods, showConfirmation, confirmationtype, onCancel, cancellable, forwardToPaytrail }) {
  const firebase = useFirebase()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [paymentOptions, setPaymentOptions] = useState([])
  const [undoClicked, setUndoClicked] = useState(false)
  const isTest = document.location.hostname === 'utest.cintoia.com' || document.location.hostname === 'localhost'
  const url =
    'https://europe-west1-falcon-328a1.cloudfunctions.net/' +
    (isTest ? 'testpaymentv3' : 'paymentv3') +
    '-init/?p=web&pm='

  useEffect(() => {
    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((tokenResult) => {
        const forms = url + selectedGw + '&auth=' + tokenResult.token + '&f=j'
        return fetch(forms)
      })
      .then((response) => response.json())
      .then((data) => {
        setPaymentOptions(data)
        // console.log(data)
        setLoading(false)
      })
      .catch((e) => console.error(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const checkoutParameterToInput = (param) => (
    <input key={param.name} type='hidden' name={param.name} value={param.value} />
  )


  const checkoutResponseToHtml = (data) =>
    data.providers
      .filter((x) => !selectedGroup || (selectedGroup && x.group === selectedGroup))
      .filter((x) => !provider || (provider && provider.gateway === 'paytrail' && x.id === provider.id))
      .filter((x) => !disabledmethods.includes(x.id))
      .map((provider) => {
        const params = provider.parameters.map((x) => checkoutParameterToInput(x))
        const _isInIframe = isInIframe()
        console.log(provider)
        return (
          <Col key={provider.name + '-col'}>
            <form key={provider.name} method='POST' action={provider.url} target={_isInIframe ? '_blank' : '_self'}>
              {params}
              <button style={buttonStyle}>
                <img src={provider.svg} alt={provider.name} />
              </button>
            </form>
          </Col>
        )
      })

  const confirmation = () => {
    if (confirmationtype === 'reservation') {
      return (
        <React.Fragment>
          <Row key={'1'} className='justify-content-center align-items-center'>
            <Col xs='12'>
              <Typography variant='h5' component='h5' style={{ textAlign: 'center' }}>
                {t('reservation_confirmation_title')}
              </Typography>
            </Col>
          </Row>
          <Row key={'2'} className='justify-content-center align-items-center'>
            <Col xs='12'>
              <Typography variant='body1' style={{ textAlign: 'center' }}>
                {t('reservation_confirmation_message')}
              </Typography>
            </Col>
          </Row>
        </React.Fragment>
      )
    }
  }

  if (paymentOptions && paymentOptions.error) {
    return <div>{paymentOptions.message}</div>
  }

  const onUndo = (id, locationid) => {
    setUndoClicked(true)
    onCancel(id, locationid)
  }


  if (forwardToPaytrail) {
    if (loading) {
      return (
        <div className='full-page-background'>
          <Container className='login-dialog'>
            <Row>
              <Col md='8' className='mx-auto'>
                <Card>
                  <CardBody>
                    <CardTitle tag='h5'>{'Maksutapa'}</CardTitle>
                      <Row className={'loose h-100 justify-content-center align-items-center'} key={'poptions'}>
                      <Spinner />
                    </Row>
                </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else {
      window.location.href = paymentOptions.href
      return null
    }
  }

  return (
    <div className='full-page-background'>
      <Container className='login-dialog'>
        {showConfirmation ? (
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardBody>{confirmation()}</CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col md='8' className='mx-auto'>
            <Card>
              <CardBody>
                <CardTitle tag='h5'>{'Maksutapa'}</CardTitle>
                {loading ? (
                  <Row className={'loose h-100 justify-content-center align-items-center'} key={'poptions'}>
                    <Spinner />
                  </Row>
                ) : (
                  <React.Fragment>
                    <Row className={'loose'} key={'poptions'}>
                      {checkoutResponseToHtml(paymentOptions)}
                    </Row>
                    <Divider />
                    <Row className={'loose'} key={'day'}>
                      <Col
                        style={{ fontSize: 'small' }}
                        dangerouslySetInnerHTML={{ __html: paymentOptions.terms }}
                      ></Col>
                    </Row>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {onCancel && cancellable ? (
          <Row>
            <Col md='8' className='mx-auto'>
              <Card>
                <CardBody>
                  {undoClicked ? <React.Fragment><ChevronLeftIcon  className='text-muted' />{' '}<Spinner size={'sm'} className='text-muted' /></React.Fragment> :
                    <Button size='small' color='link' className='text-muted' onClick={() => onUndo(cancellable.id, cancellable.locationid)}>
                      <ChevronLeftIcon /> {t('undo_payment')}
                    </Button>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}
      </Container>
      <Prompt
        when={showConfirmation && !undoClicked}
        message={(location, action) => {
          return t('reservation_back_prompt')
        }}
      />
    </div>
  )
}

export default  PaymentOptions
